@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.shine {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.shine::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%; /* Extend the gradient width */
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 0.5s ease-in-out infinite; /* Smooth out animation */
  transform: skewX(-20deg);
}
